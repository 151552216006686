.nav-bar{
  padding: 12px 24px 12px 16px;
  box-shadow: inset 0px -1px 0px #F0F0F0;
  .logo{
    max-height: 54px;
    max-width: 300px;
    object-fit: contain;
    cursor: pointer;
  }
  .company{
    max-height: 54px;
    max-width: 320px;
    object-fit: contain;
  }
  .btn-profile{
    margin-right: 16px;
  }
  .profile-img{
    width: 50px;
    height: 50px;
  }
  .icon{
    margin-left: 8px;
  }
}
.documenter-menus{
  max-width: 360px;
  min-width: 200px;
  background-color: #FFF;
  padding: 8px 0;
  .btn-profile{
    padding: 6px 12px;
    margin-bottom: 4px;
  }
  .email{
    line-height: 14px;
  }
  .usename{
    margin-top: 6px;
    line-height: 20px;
  }
  .profile-signout{
    margin-top: 8px;
    .profile {
      color: #072C3C;
      cursor: pointer;
      text-decoration: none;
    }
  
    .logout {
      color: #FF3333;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .profile-img{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
  .menu-entry{
    height: 35px;
    padding: 6px 12px;
    text-decoration: none;
    &:nth-child(1){
      margin-bottom: 12px;
    }
    &.link{
      cursor: pointer;
    }
  }
  .mod-logo{
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
}
.menu-overlay{
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  position: absolute;
  overflow: visible;
  filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 0px 2px);
}