@font-face {
    font-family: 'icomoon';
    src: url('./assets/fonts/icomoon.eot?sifyyl');
    src: url('./assets/fonts/icomoon.eot?sifyyl#iefix') format('embedded-opentype'),
      url('./assets/fonts/icomoon.ttf?sifyyl') format('truetype'),
      url('./assets/fonts/icomoon.woff?sifyyl') format('woff'),
      url('./assets/fonts/icomoon.svg?sifyyl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"],
  [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow-round-forward:before {
    content: "\e901";
  }
  .icon-back:before {
    content: "\e902";
  }
  .icon-calender:before {
    content: "\e903";
  }
  .icon-caret-down:before {
    content: "\e904";
  }
  .icon-caret-up:before {
    content: "\e905";
  }
  .icon-chavron-down:before {
    content: "\e906";
  }
  .icon-check-o:before {
    content: "\e907";
  }
  .icon-circular:before {
    content: "\e908";
  }
  .icon-close-eye:before {
    content: "\e909";
  }
  .icon-contents:before {
    content: "\e90a";
  }
  .icon-copy:before {
    content: "\e90b";
  }
  .icon-delete:before {
    content: "\e90c";
  }
  .icon-documents:before {
    content: "\e90d";
  }
  .icon-dot:before {
    content: "\e90e";
  }
  .icon-download:before {
    content: "\e90f";
  }
  .icon-drive:before {
    content: "\e910";
  }
  .icon-edit-pencil:before {
    content: "\e911";
  }
  .icon-evaluation:before {
    content: "\e912";
  }
  .icon-export:before {
    content: "\e913";
  }
  .icon-file-choose:before {
    content: "\e914";
  }
  .icon-file-csv:before {
    content: "\e915";
  }
  .icon-file-pdf:before {
    content: "\e916";
  }
  .icon-learning:before {
    content: "\e917";
  }
  .icon-link-new-tab:before {
    content: "\e918";
  }
  .icon-open-eye:before {
    content: "\e919";
  }
  .icon-open-folder-o:before {
    content: "\e91a";
  }
  .icon-open-folder:before {
    content: "\e91b";
  }
  .icon-plus-lite:before {
    content: "\e91c";
  }
  .icon-plus:before {
    content: "\e91d";
  }
  .icon-presentations:before {
    content: "\e91e";
  }
  .icon-profile-caret:before {
    content: "\e91f";
  }
  .icon-save:before {
    content: "\e920";
  }
  .icon-search:before {
    content: "\e921";
  }
  .icon-send:before {
    content: "\e922";
  }
  .icon-share:before {
    content: "\e923";
  }
  .icon-signout:before {
    content: "\e924";
  }
  .icon-simulation:before {
    content: "\e925";
  }
  .icon-sort:before {
    content: "\e926";
  }
  .icon-stats:before {
    content: "\e927";
  }
  .icon-upload:before {
    content: "\e928";
  }
  .icon-variables:before {
    content: "\e929";
  }
  .icon-warning-icon:before {
    content: "\e92a";
  }