.doc-table {
  .cell {
    width: 100%;
  }
  .cel-circle{
    width: 12px;
    height: 12px;
    margin-right: 0.8rem;
    border-radius: 50%;
  }
}
.table-ct{
  padding: 0.8rem 0;
  .page-change{
    margin: 16px 0;
  }
}
.data-table-search{
  padding: 0.8rem;
  .input{
    margin-bottom: 0;
  }
  .label-cont{
    display: none;
  }
  .reset{
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 0 0.8rem;
  }
}
.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}
.truncate-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  /* Hides any content that overflows the container */
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 90%;
}
.truncate-ellipsis:hover {
  max-width: none; /* Remove the max-width on hover */
  white-space: wrap;
  text-overflow: auto;
  display: block;
}
.mb-title {
  margin-bottom: 20px;
}
.mb-stats {
  margin-bottom: -18px;
}
.mr {
  margin-right: 5px;
}
.export-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 10px;
  padding-right: 200px;
}

.export-top-left-logo {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px;
}

.export-top-right-logo {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px;
}

.export-bottom-left-logo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-left: -5px;
}

.export-bottom-right-logo {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.export-bottom-page-no{
  position: absolute;
  bottom: 0px;
  left: 45%;
  padding-bottom: 15px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.export-center-content {
  padding-top: 80px;
}
.continuity-table {
  position: relative;
  min-width: 1000px;
  min-height: 700px;
}