.inc-res-modal {
  .ant-modal-content {
    border-radius: 0;
    padding: 16px;
  }

  .ant-modal-title {
    margin-left: 8px;
  }

  .ant-modal-close {
    top: 26px;
    right: 24px;
    width: 64px;
  }

  .btn-menu {
    width: 28px;
    height: 24px;
    border-color: #0133CC;
    margin-left: 0;
    padding-top: 3px !important;

    .icon-dot {
      font-size: 2px;
    }
  }
}

.resp-menu {
  .menu-list {
    padding: 0;
  }

  .container {
    width: 300px;
    min-height: 100px;

    .footer {
      border-top: 1px solid #F0F0F0;
    }
  }

  .user-info {
    padding: 16px;
  }
}

.change-res-modal {
  .desc {
    margin-top: 4px;
    margin-bottom: 16px;
  }
}

.new-test-responder {
  .ant-modal-content {
    padding: 16px 0 0 0;
  }

  .ant-modal-header {
    padding-left: 24px;
  }

  .ant-modal-body {
    padding: 6px 24px 0 24px;
  }

  .ant-modal-footer {
    border-top: 1px solid #F0F0F0;
    margin-top: 36px;
    padding: 10px 24px 10px 24px;
  }
}

.new-test-modal {
  .form {
    padding-top: 16px;

    .ant-form-item-label {
      width: 96px;
    }

    .due_date {
      margin-left: 8px;

      .ant-form-item-label {
        width: 86px;
      }
    }
  }

  .label-cont {
    min-width: 96px !important;

    .label {
      width: 90%;
      text-align: right;
    }
  }
}

.view-test {
  position: relative;

  .report-wait {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: none;
  }

  .header {
    padding: 16px 50px 16px 24px;
    border-bottom: 1px solid #DFDFDF;

    .btn-back {
      padding-left: 0;
    }

    .fields {
      .form-input {
        width: 186px;
        margin-left: 24px;
        margin-bottom: 0;
      }
    }
  }

  .scenario {
    max-height: 150px;
    margin-top: 12px;
    padding-right: 50px;
  }

  .side-bar {
    height: calc(100% - 86px);

    &.close {
      width: 50px;
      // padding: 0;
      padding: 10px 2px;
      transition-timing-function: ease-out;

      .sidebar-container {
        transition-timing-function: ease-out;
        opacity: 0;
      }
    }

    &.open {
      // padding: 20px 16px 0 16px;
      padding: 10px 2px;
      transition-timing-function: ease-in;

      .sidebar-container {
        transition-timing-function: ease-out;
        opacity: 1;
      }
    }

    .sidebar-container {
      transition: opacity 0.25s;
    }

    width: 306px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: width 0.25s,
    padding 0.25s;
    background-color: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    .footer {
      padding-top: 12px;
      box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
    }

    .field {
      margin-bottom: 20px;

      .key-label {
        width: 30%;
        text-align: right;
        margin-right: 12px;
      }

      .btn-add-resp {
        border: 1px solid #0133CC;
        padding: 1px 4px;
        line-height: 12px;
        align-self: flex-end;
        margin-top: 4px;
      }
    }

    .btn-resp-del {
      padding: 0 4px;
    }
  }

  .container {
    background-color: #F9F9F9;
    position: relative;
    padding-top: 2px;
    padding-right: 56px;
  }

  .history-sec {
    width: 38%;
    max-width: 535px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: #FFF;
    position: relative;
    z-index: 1;

    .header {
      height: 56px;
      padding: 0 24px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    }

    .footer {
      padding: 10px 20px 28px 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);

      .btn-submit {
        position: absolute;
        bottom: 8px;
        right: 20px;
      }
    }

    .documents.documents .select-btn {
      min-width: 100px;
    }

    .avator {
      width: 36px;
      height: 32px;
      border-radius: 16px;
      background-color: #DDFFFF;
      border: 1px solid #238787;
      margin-right: 12px;
    }

    .comment-card {
      .avator {
        width: 32px;
      }

      .link-evd {
        padding: 0;
        margin-top: 8px;

        span {
          display: block;
          width: 100%;
          word-break: break-all;
        }
      }

      .comment-text {
        margin-bottom: 0;
        margin-top: 4px;
      }

      padding: 24px 20px 0px 20px;
    }
  }

  .toggle-sec {
    position: relative;
    z-index: 1;
    padding: 8px 10px;
    // width: calc(100% - 50px);

    .link-evd {
      padding: 0;
    }

    .panel {
      border-radius: 1px;

      .ant-collapse-content {
        padding: 0px;
      }

      .ant-collapse-content-box {
        padding: 16px;
      }
    }

    .collapse {
      margin-bottom: 16px;
      border-color: #D9D9D9;
      border-radius: 1px;
      background-color: #FAFAFA;

      .ant-collapse-item:last-child>.ant-collapse-content {
        border-radius: 0 0 1px 1px;
      }
    }
  }

  .details-form {
    .form-input .ant-form-item-label {
      width: 136px;
    }

    .form-input.rca {
      .ant-form-item-label>label::after {
        margin-top: 22px;
      }
    }
    .reference{
      .icon-ref-link::after{
        content: "\e918";
        margin-left: 4px;
        font-size: 1.2rem;
      }
    }
  }

  .closure-form {
    .form-input .ant-form-item-label {
      width: 90px;
    }
  }

  .task-view {
    .create-task-btn {
      margin-left: 16px;
    }

    .ant-collapse-content-box {
      max-height: 320px;
    }

    .doc-table {
      border-radius: 2px;
      margin-top: 8px;
      border: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}

.create-task-form {
  margin-top: 20px;

  .form-input .label {
    width: 81px;
  }

  .field-title {
    width: 46%;
  }

  .field-priority {
    width: 20%;
    margin-left: 15px;
  }

  .field-dept {
    width: 30%;
  }

  .field-source {
    width: 60%;
    margin-left: 80px;
  }

  .field-due {
    width: 30%;
    margin-left: 15px;
  }
}

.export-form {
  .form-input .ant-form-item-label {
    width: 90px;
  }
}

.send-review {
  max-width: 550px;
}

.create-task {
  max-width: 937px;
}

.test-stats {
  width: 100%;
  max-width: 829px;
  padding: 0 24px;

  .btn-export {
    margin-bottom: 8px;
    padding-right: 0px;

    .icon {
      margin: 0 0 0 8px;
    }
  }

  .form-input {
    width: 200px;
    margin: 0;
  }

  .title {
    min-width: 190px;
  }

  .gap {
    margin-bottom: 60px;
  }

  .stats-tbl {
    table-layout: fixed;
    border-spacing: 0;
    // margin-top: 24px;

    tr .cell {
      padding: 8px;
      border-top: 1px solid #238787;
      border-left: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

    tr .header-cell {
      padding: 8px;
      border-top: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &:first-child {
        border-left: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

    tbody tr {
      .cell {
        &:not(:first-child) {
          border-left: none;
        }

        &:nth-child(2) {
          color: black;
          background-color: #DFF;
        }
      }

      &:last-child {
        .cell {
          border-bottom: 1px solid #238787;
        }
      }

      &:nth-child(odd) {
        .cell {
          background-color: #DFF;
        }
      }

      /* Styling for first row below header */
      &:first-child {
        .cell {
          color: black;
        }
      }
    }
  }
}

.share-confirm {
  .emails {
    padding: 0px 0 0 20px;
    margin-top: 2px;
  }
}