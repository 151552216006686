.menus {
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  .item{
    padding: 6px 12px;
    height: 32px;
    min-width: 150px;
    border: none !important;
    box-shadow: none !important;
    text-decoration: none;
    &:hover{
      background-color: #F5F5F5;
    }
    &.primary:hover{
      color: #0033CC;
    }
    &.empty:hover{
      background-color: transparent;
      cursor: auto;
    }
  }
  .icon{
    width: 24px;
    margin-right: 6px;
  }
}